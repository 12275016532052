<template lang="pug">
tr.loan-schedule-invoice-list-item
  td {{ $t('common:formatDate', { value: invoice.paymentDate }) }}
  td.money.has-popover(:id = '`${invoiceUid}-vat`') {{ invoice.balanceWithVat | money }}
  td.animated.fadeIn(v-if = 'editing && editable')
      fi-money-input.form-control-sm.form-control(
        :value = 'invoice.principal'
        @input = 'changePrincipal'
      )
  td.animated.fadeIn.money(v-else) {{ invoice.principal | money }}
  td.money {{ invoice.interest | money }}
  td.money.has-popover(:id = '`${invoiceUid}-fee`') {{ invoice.fee | money }}
  td.money {{ invoice.vat | money }}
  td.money {{ sum | money }}
  td.money {{ paidSum | money }}
  td.money {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(invoice.status)}`') {{ status.human }}
  b-popover(
    :target   = '`${invoiceUid}-fee`'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row(v-for = '(value, key) in invoice.feeDetails')
      .col.text-nowrap {{ key }}
      .col.text-nowrap {{ value | money }}
  b-popover(
    :target   = '`${invoiceUid}-vat`'
    triggers  = 'hover'
    placement = 'bottom'
  )
    div
      .row
        .col {{ $t('balance') }}
        .col.text-nowrap {{ invoice.balance | money }}
      .row
        .col {{ $t('vat') }}
        .col.text-nowrap {{ invoice.vatBalance | money }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import { mapGetters } from 'vuex'
import FiMoneyInput from '@/components/FiMoneyInput'

export default {
  name: 'loan-schedule-invoice-vat-list-item',

  components: { FiFormField, FiMoneyInput },

  props: {
    invoice: {
      type: Object,
      required: true
    },
    editable: Boolean,
    editing: Boolean
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    invoiceUid () {
      return `invoice_${this._uid}`
    },
    sum () {
      const { principal, interest, fee, vat = 0 } = this.invoice
      return principal + interest + fee + vat
    },
    paidSum () {
      const { amountPaid, vatAmountPaid = 0 } = this.invoice
      return amountPaid + vatAmountPaid
    },
    status () {
      return this.classifierById(this.invoice.status.classifier, this.invoice.status.id)
    }
  },
  methods: {
    changePrincipal (value) {
      this.$emit('input', {
        paymentDate: this.invoice.paymentDate,
        amount: value
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.has-popover {
  cursor: pointer;
}
</style>
